import React, { useState, useEffect } from 'react';
import {
  Container, Paper, Typography, TextField, Radio, RadioGroup, FormControlLabel, FormControl,
  FormLabel, Button, Select, MenuItem, InputLabel, Grid, Box, makeStyles, Snackbar, Slide
}
  from '@material-ui/core';
import Footer from '../components/Footer';
import EstadoSelect from './components/EstadoSelect';
import { InputCnpj, InputCpf, InputPhone, InputRg } from './components/MaskedInput';
import DoneIcon from '@material-ui/icons/Done'
import { useHistory, useLocation } from 'react-router-dom';
import { InputViaCep } from './components/InputViaCep';
import { ErrorMessageDialog, MuiDialog } from './components/DialogErrorMessage';
import { useParams } from 'react-router-dom';

const CustomSnackbar = ({ open, onClose }) => {
  return (
    <Snackbar
      style={{
        fontFamily: 'Arial',
        backgroundColor: '#4CAF50',
        color: 'white',
        borderRadius: '10px',
        padding: '10px',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DoneIcon style={{ marginRight: '8px' }} />
        Seus dados foram enviados com sucesso
      </div>
    </Snackbar>
  );
};

const useStyles = makeStyles(( theme ) => ({
  mainTitle: {
    margin: '0',
    padding: '3px',
    textAlign: 'center',
    color: '#black',
    textShadow: '0px 1px 1px black'
  },

  stylePaper: {
    padding: '26px',
    marginTop: '20px',
    textShadow: '0px 0px 0px black',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },

}));

const SlideTransition = React.forwardRef((props, ref) => {
  return <Slide ref={ref} {...props} direction="left" />;
});

const CORES_SISTEMA = {'sischef': "#e91e63", 'qrpedir': "#253257", 'sisagil': "#588DA1"};

const Cadastramento = () => {

  const params = useParams();
  const sistema = params.sistema || 'sischef';
  let sistemaLabel = "Sischef"

  if (sistema === 'qrpedir') {
    sistemaLabel = "QrPedir"
  }

  if (sistema === 'sisagil') {
    sistemaLabel = "Sisagil"
  }

  let history = useHistory();
  let location = useLocation();
  const classes = useStyles();

  const [emailSent, setEmailSent] = useState(false);
  const handleEmailSentClose = () => { setEmailSent(false); };
  const [emailError, setEmailError] = useState(false);

  const handleOpenErrorDialog = () => setEmailError(true);
  const handleCloseErrorDialog = () => setEmailError(false);

  const [loading, setLoading] = useState(false);
  const [loadingCepEmpresa, setLoadingCepEmpresa] = useState(false);
  const [loadingCepResponsavel, setLoadingCepResponsavel] = useState(false)

  const [formData, setFormData] = useState({
    razaoSocial: '', nomeFantasia: '', possuiCnpj: 'sim', endereco: '', bairro: '', empresaCep: '', numero: '', municipio: '', estado: '', telefoneEmpresa: '',
    emailEmpresa: '', responsavelNome: '', responsavelCep: '', responsavelEndereco: '', responsavelBairro: '', responsavelMunicipio: '', responsavelNumero: '', responsavelEstado: '', responsavelTelefone: '', responsavelEmail: '',
    inscricaoEstadual: '', cpf: '', rg: '', cnpj: '', contadorNome: '', regimeTributario: '', contadorEmail: '', contadorTelefone: '',
  });

  useEffect(() => {
    if (location.hash === "#email") {
      history.push("/");
    }
  }, []);

  const upper = (str)=>{
    return (str || '').toUpperCase();
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({ ...prevData, [name]: upper(value) }));
  };

  const formatDataForEmail = (formData) => {
    return `
      <p><strong>Dados da Empresa - Sistema ${sistemaLabel}</strong></p>
      <p>Origem: ${sistemaLabel}</p>

      <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse; ">
        <tr><td><strong>Razão Social:</strong></td><td>${formData.razaoSocial}</td></tr>
        <tr><td><strong>Nome Fantasia:</strong></td><td>${formData.nomeFantasia}</td></tr>
        <tr><td><strong>CNPJ:</strong></td><td>${formData.cnpj}</td></tr>
        <tr><td><strong>Inscrição Estadual (IE):</strong></td><td>${formData.inscricaoEstadual}</td></tr>
        <tr><td><strong>Endereço:</strong></td><td>${formData.endereco}, ${formData.numero}, ${formData.bairro}</td></tr>
        <tr><td><strong>Município/Estado:</strong></td><td>${formData.municipio} - ${formData.estado}</td></tr>
        <tr><td><strong>CEP:</strong></td><td>${formData.empresaCep}</td></tr>
        <tr><td><strong>Telefone:</strong></td><td>${formData.telefoneEmpresa}</td></tr>
        <tr><td><strong>E-mail:</strong></td><td>${formData.emailEmpresa}</td></tr>
      </table>

      <p><strong>Dados do Responsável</strong></p>
      <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;">
        <tr><td><strong>Nome:</strong></td><td>${formData.responsavelNome}</td></tr>
        <tr><td><strong>CPF:</strong></td><td>${formData.cpf}</td></tr>
        <tr><td><strong>RG:</strong></td><td>${formData.rg}</td></tr>
        <tr><td><strong>CEP:</strong></td><td>${formData.responsavelCep}</td></tr>
        <tr><td><strong>Endereço:</strong></td><td>${formData.responsavelEndereco}, ${formData.responsavelNumero}, ${formData.responsavelBairro}</td></tr>
        <tr><td><strong>Município/Estado:</strong></td><td>${formData.responsavelMunicipio} - ${formData.responsavelEstado}</td></tr>
        <tr><td><strong>Telefone:</strong></td><td>${formData.responsavelTelefone}</td></tr>
        <tr><td><strong>E-mail:</strong></td><td>${formData.responsavelEmail}</td></tr>
      </table>

      <p><strong>Dados do Contador</strong></p>
      <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse; ">
        <tr><td><strong>Nome:</strong></td><td>${formData.contadorNome}</td></tr>
        <tr><td><strong>Regime Tributário da Empresa:</strong></td><td>${formData.regimeTributario}</td></tr>
        <tr><td><strong>E-mail do Contador:</strong></td><td>${formData.contadorEmail}</td></tr>
        <tr><td><strong>Telefone do Contador:</strong></td><td>${formData.contadorTelefone}</td></tr>
      </table>
    `;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true) // ativa o estado de carregamento


    // Obtendo as variáveis de ambiente do .env
    const API_URL = process.env.REACT_APP_API_MAIL;
    const API_TOKEN = process.env.REACT_APP_API_TOKEN;

    const formattedData = formatDataForEmail(formData);

    const subject = `Nova ficha de cadastro - ${formData.nomeFantasia}`;

    const emailData = {
      from: 'noreply@parseint.com.br',
      fromName: `Ficha de cadastro`,
      to: 'fichacadastro@parseint.com.br',
      subject: subject,
      html: formattedData,
    };



    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        },
        body: JSON.stringify(emailData)
      });
  
      if (!response.ok) {
        throw new Error(`Não foi possível enviar o e-mail referente ao seu cadastro. Erro na requisição: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Resposta da API:", data);
      setEmailSent(true);

      setTimeout(() => {
          window.location.href = "https://sischef.com/obrigado/";
        }, 4000);

    } catch (error) {
      console.error("Erro ao enviar email:", error.message);
      handleOpenErrorDialog();
    }finally {
      setLoading(false)
    }


  }

  function handleSuccess(key, data) {
    if (key === 'empresaCep') {
      setFormData((prevData) => ({
        ...prevData,
        'estado': upper(data.uf) || '',
        'bairro': upper(data.bairro) || '',
        'endereco': upper(data.logradouro) || '',
        'municipio': upper(data.localidade) || '',
      }));
      return
    }

    setFormData((prevData) => ({
      ...prevData,
      'responsavelEstado': upper( data.uf ) || '',
      'responsavelBairro': upper(data.bairro) || '',
      'responsavelEndereco': upper(data.logradouro) || '',
      'responsavelMunicipio': upper(data.localidade) || '',
    }));
  }

  function handleLoading(key, value) {
    if (key === 'empresaCep') {
      setLoadingCepEmpresa(value)
    } else {
      setLoadingCepResponsavel(value)
    }
  }

  return (

    <Container maxWidth="md">

      <Box position="absolute" left={0} top={0} zIndex={-1} height="10px" width="100%" as="header" 
        bgcolor={CORES_SISTEMA[sistema]}>
        
      </Box>

      <form onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="center" margin="20px">
          <img src={`/images/logo-${sistema}-256.png`} alt="logo sischef" width="110" height="110" />
        </Box>


        <Typography className={classes.mainTitle}
          variant="h4">{sistemaLabel} - Cadastramento</Typography>

        <Paper  variant='outlined' className={classes.stylePaper}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="h5">Dados da Empresa</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth onChange={handleChange}
                label="Razão social"
                name="razaoSocial" required
                value={formData.razaoSocial}
              />
              </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nome fantasia"
                name="nomeFantasia"
                value={formData.nomeFantasia}
                onChange={handleChange}
                fullWidth
              /></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <InputViaCep
                name="empresaCep"
                value={formData.empresaCep}
                onChange={handleChange}
                onSuccess={(data) => handleSuccess('empresaCep', data)}
                onLoading={(value) => handleLoading('empresaCep', value)}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                label="Endereço"
                name="endereco"
                value={loadingCepEmpresa ? 'Carregando...' : formData.endereco}
                disabled={loadingCepEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
              <TextField
                label="Número"
                name="numero"
                value={formData.numero}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                label="Bairro"
                name="bairro"
                value={loadingCepEmpresa ? 'Carregando...' : formData.bairro}
                disabled={loadingCepEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                label="Município"
                name="municipio"
                value={loadingCepEmpresa ? 'Carregando...' : formData.municipio}
                disabled={loadingCepEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EstadoSelect
                value={formData.estado}
                loading={loadingCepEmpresa}
                disabled={loadingCepEmpresa}
                name='estado'
                onChange={handleChange}>
              </EstadoSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Telefone"
                name="telefoneEmpresa" required
                value={formData.telefoneEmpresa}
                onChange={handleChange}
                InputProps={{ inputComponent: InputPhone }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail"
                name="emailEmpresa" required
                value={formData.emailEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel style={{ fontWeight: 'bold', color: 'black', padding: '20px 0em 0em', lineHeight: '4em' }} component="legend" >Sua empresa já possui CNPJ?</FormLabel>
              <RadioGroup
                name="possuiCnpj"
                value={formData.possuiCnpj}
                onChange={handleChange}
                row>
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {formData.possuiCnpj === 'sim' ?
            <Grid item xs={12}>
              <TextField
                label="CNPJ"
                name="cnpj" required
                value={formData.cnpj}
                onChange={handleChange}
                InputProps={{ inputComponent: InputCnpj }}
                fullWidth
              />
              <Grid item xs={12}>
                <TextField
                  label="Inscrição Estadual (IE)"
                  name="inscricaoEstadual"
                  value={formData.inscricaoEstadual}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            : null}

        </Paper>
        <Paper variant='outlined' className={classes.stylePaper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="h5">Dados do Responsável</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nome"
                name="responsavelNome" required
                value={formData.responsavelNome}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputViaCep
                name="responsavelCep"
                value={formData.responsavelCep}
                onChange={handleChange}
                onSuccess={(data) => handleSuccess('responsavelCep', data)}
                onLoading={(value) => handleLoading('responsavelCep', value)}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                label="Endereço"
                name="responsavelEndereco"
                value={loadingCepResponsavel ? 'Carregando...' : formData.responsavelEndereco}
                disabled={loadingCepResponsavel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Número"
                name="responsavelNumero"
                value={formData.responsavelNumero}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <TextField
                label="Bairro"
                name="responsavelBairro"
                value={loadingCepResponsavel ? 'Carregando...' : formData.responsavelBairro}
                disabled={loadingCepResponsavel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
       
            <Grid item xs={12} md={6}>
              <TextField
                label="Município"
                name="responsavelMunicipio"
                value={loadingCepResponsavel ? 'Carregando...' : formData.responsavelMunicipio}
                disabled={loadingCepResponsavel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <EstadoSelect
                  value={formData.responsavelEstado}
                  disabled={loadingCepResponsavel}
                  loading={loadingCepResponsavel}
                  name='responsavelEstado'
                  onChange={handleChange}>
                </EstadoSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Telefone"
                name="responsavelTelefone" required
                value={formData.responsavelTelefone}
                InputProps={{ inputComponent: InputPhone }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail"
                name="responsavelEmail" required
                value={formData.responsavelEmail}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="CPF"
                name="cpf" required
                value={formData.cpf}
                InputProps={{ inputComponent: InputCpf }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="RG"
                name="rg"
                value={formData.rg}
                InputProps={{ inputComponent: InputRg }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>

        { sistema === 'sischef' && (
        <Paper variant='outlined' className={classes.stylePaper}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 'bold', textAlign: 'center' }} variant="h5">
                  Dados do contador (para Emissão Fiscal)
                </Typography>
                <Typography style={{  textAlign: 'center', color: '#FF4444' }} variant="subtitle1">
                  *Preencha estes campos somente se for realizar emissão fiscal </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nome do seu contador"
                name="contadorNome"
                value={formData.contadorNome}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Regime Tributário</InputLabel>
                <Select
                  name="regimeTributario"
                  value={formData.regimeTributario}
                  onChange={handleChange}
                >
                  <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
                  <MenuItem value="Lucro Real">Lucro Real</MenuItem>
                  <MenuItem value="Não Sei">Não sei</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail do contador"
                name="contadorEmail"
                value={formData.contadorEmail}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Telefone do contador"
                name="contadorTelefone"
                value={formData.contadorTelefone}
                InputProps={{ inputComponent: InputPhone }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          
        </Paper> )}

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end', marginTop: '25px' }}>
            <Button type='submit' variant="contained" color="primary"
              disabled={loading || emailSent} size='large'
              onSubmit={handleSubmit}>
              { (loading && !emailSent) ? 'Enviando...' : 'Enviar dados'}
              { (emailSent) && 'Enviado com sucesso' }
            </Button>
          </Grid>

        <Button aria-label="add" style={{ position: 'fixed', zIndex: 10, bottom: '8px', right: '30px', color: "#FF2A2A" }}>
          <MuiDialog />
        </Button>
      </form>
      <Footer />

      <CustomSnackbar open={emailSent} onClose={handleEmailSentClose} style={{ textDecoration: 'none' }} />
      <ErrorMessageDialog open={emailError} onClose={handleCloseErrorDialog} />

    </Container>
  );
};

export default Cadastramento;
